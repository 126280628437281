import { Image } from "../../Image/Image";
import { HeadingInfo } from "../../Project/HeadingInfo/HeadingInfo";
import { AboutProject } from "../../Project/AboutProject/AboutProject";
import { TitleTextProjects } from "../../Project/TitleTextProjects/TitleTextProjects";
import { TextProjects } from "../../Project/TextProjects/TextProjects";
import { ProjectOtherProjects } from "../../Project/ProjectOtherProjects/ProjectOtherProjects";

import imageBigFirst from "../../../images/crpp-big-first.jpg";
import imageBigSecond from "../../../images/crpp-big-second.jpg";
import imageBigThird from "../../../images/crpp-big-third.jpg";
import imageBigFourth from "../../../images/crpp-big-fourth.jpg";
import imageBigFifth from "../../../images/crpp-big-fifth.jpg";
import imageBigSixth from "../../../images/crpp-big-sixth.jpg";
import imageBigSeventh from "../../../images/crpp-big-seventh.jpg";
import imageBigEighth from "../../../images/crpp-big-eighth.jpg";
import imageBigNinth from "../../../images/crpp-big-ninth.jpg";
import imageBigTenth from "../../../images/crpp-big-tenth.jpg";
import imageBigEleventh from "../../../images/crpp-big-eleventh.jpg";
import imageBigTwelfth from "../../../images/crpp-big-twelfth.jpg";
import imageBigThirteenth from "../../../images/crpp-big-thirteenth.jpg";
import imageBigFourteenth from "../../../images/crpp-big-fourteenth.jpg";
import imageBigFifteenth from "../../../images/crpp-big-fifteenth.jpg";
import imageBigSixteenth from "../../../images/crpp-big-sixteenth.jpg";
import imageBigSeventeenth from "../../../images/crpp-big-seventeenth.jpg";
import imageBigEighteenth from "../../../images/crpp-big-eighteenth.jpg";
import imageBigNineteenth from "../../../images/crpp-big-nineteenth.jpg";
import imageBigTwentieth from "../../../images/crpp-big-twentieth.jpg";
import imageBigTwentyFirst from "../../../images/crpp-big-twenty-first.jpg";

import imageMediumFirst from "../../../images/crpp-medium-first.jpg";
import imageMediumSecond from "../../../images/crpp-medium-second.jpg";

import trice from "../../../images/main-projects-trice.jpg";
import infomediji from "../../../images/infomediji-big-first.jpg";

export const Crpp = (props) => {
    const openTricePage = () => {
        props.history.push('/projects/trice');
    }

    const openInfomedijiPage = () => {
        props.history.push('/projects/infomediji');
    }

    return (
        <div className="project">
            <HeadingInfo
                name={'Мой бизнес'}
                client={['Мой бизнес', 'Государственный фонд развития предпринимательства']}
                profile={['Сайт']}
                date={'2024'}
            />

            <Image
                image={imageBigFirst}
                alt={'Мой бизнес'}
            />

            <AboutProject
                text={'Центр «Мой Бизнес» Санкт-Петербург — это государственный фонд развития предпринимательства для малого и среднего бизнеса.'}
            />

            <section className="project-task">
                <TitleTextProjects
                    title={'Проект и задача'}
                    text={['На сайте можно узнать всё про меры господдержки, получить бесплатные консультации или подать заявку на субсидию.', 'Нашей задачей было провести полный редизайн портала, переосмыслив структуру личного кабинета и сайта, качественно улучшив UX всей платформы.']}
                />

                <Image
                    image={imageBigSecond}
                    alt={'Мой бизнес'}
                />
            </section>

            <section className="project-task_type_border-none">
                <TitleTextProjects
                    title={'Личный кабинет'}
                    text={['Главная страница', 'Мы оптимизировали Главную страницу: сделали ее информативнее: добавили виджет с услугами, блок новостей и баннеры для важных объявлений.']}
                />

                <div className="project__images-container">
                    <Image
                        image={imageBigThird}
                        alt={'Мой бизнес'}
                    />

                    <Image
                        image={imageBigFourth}
                        alt={'Мой бизнес'}
                    />

                    <Image
                        image={imageBigFifth}
                        alt={'Мой бизнес'}
                    />
                </div>
            </section>

            <section className="project-task_type_border-none project-task_type_padding-top">
                <TextProjects
                    text={['Календарь событий', 'Раньше регистрации пользователя и события центра были объедины в один календарь – это вызывало путаницу.', 'Теперь все по-другому: у пользователя есть свой собственный календарь, где он может управлять своими мероприятиями и узнать все детали о своих записях в коворкинг, консультациях и обучении.']}
                />

                <div className="project__images-container">
                    <Image
                        image={imageBigSixth}
                        alt={'Мой бизнес'}
                    />

                    <Image
                        image={imageBigSeventh}
                        alt={'Мой бизнес'}
                    />
                </div>
            </section>

            <section className="project-task_type_border-none project-task_type_padding-top">
                <TextProjects
                    text={['Мероприятия', 'Мы улучшили поиск мероприятий: теперь есть удобные фильтры по типу: обучение, лекция и др. , а также разделения по формату: очно или онлайн. Карточка события теперь отражает главную информацию: дату, время, спикера и формат. А на странице самого события также появились страницы спикера и участников мероприятия']}
                />

                <div className="project__images-container">
                    <Image
                        image={imageBigEighth}
                        alt={'Мой бизнес'}
                    />

                    <Image
                        image={imageBigNinth}
                        alt={'Мой бизнес'}
                    />

                    <Image
                        image={imageBigTenth}
                        alt={'Мой бизнес'}
                    />

                    <Image
                        image={imageBigEleventh}
                        alt={'Мой бизнес'}
                    />
                </div>
            </section>

            <section className="project-task_type_border-none project-task_type_padding-top">
                <TextProjects
                    text={['Мой бизнес', 'Структуру заявок мы разбили на смысловые блоки и разместили их на одной странице так, чтобы заполнение происходило поэтапно. Мы также переписали все подсказки и убрали лишние юниты.']}
                />

                <div className="project__images-container">
                    <div className="project__couple-images">
                        <Image
                            image={imageMediumFirst}
                            alt={'Мой бизнес'}
                        />

                        <Image
                            image={imageMediumSecond}
                            alt={'Мой бизнес'}
                        />
                    </div>

                    <Image
                        image={imageBigTwelfth}
                        alt={'Мой бизнес'}
                    />

                    <Image
                        image={imageBigThirteenth}
                        alt={'Мой бизнес'}
                    />

                    <Image
                        image={imageBigFourteenth}
                        alt={'Мой бизнес'}
                    />
                </div>
            </section>

            <section className="project-task_type_border-none project-task_type_padding-top">
                <TextProjects
                    text={['Система уведомлений', 'Мы пересобрали всю навигацию раздела и дали понятные названия пунктам меню. Также мы пересобрали флоу всех заявок, разделив их на шаги. А еще значительно улучшили взаимодействие с отчетностью и таблицами, добавив сортировки,  фильтрацию и систему тегов.']}
                />

                <div className="project__images-container">
                    <Image
                        image={imageBigFifteenth}
                        alt={'Мой бизнес'}
                    />

                    <Image
                        image={imageBigSixteenth}
                        alt={'Мой бизнес'}
                    />
                </div>
            </section>

            <section className="project-task project-task_type_padding-top">
                <TextProjects
                    text={['Информационный портал', 'Кроме личного кабинета, мы также разработали информационный портал с большой базой данных для бизнеса, доступный всем. Он содержит статьи, бесплатные услуги, видео и полезные списки объектов. А также новости и детальную информацию о самом фонде.']}
                />

                <div className="project__images-container">
                    <Image
                        image={imageBigSeventeenth}
                        alt={'Мой бизнес'}
                    />

                    <Image
                        image={imageBigEighteenth}
                        alt={'Мой бизнес'}
                    />

                    <Image
                        image={imageBigNineteenth}
                        alt={'Мой бизнес'}
                    />

                    <Image
                        image={imageBigTwentieth}
                        alt={'Мой бизнес'}
                    />

                    <Image
                        image={imageBigTwentyFirst}
                        alt={'Мой бизнес'}
                    />
                </div>
            </section>

            <section className="project__result">
                <TitleTextProjects
                    title={'Результат'}
                    text={['Как результат мы спроектировали весь проект от брифинга и исследований до передачи дизайна менее чем за 4 месяца. Было спроектировано более 250 страниц с мобильной адаптацией и подробной документацией для разработчиков.']}
                />

                <div className="project__result-content">
                    <div>
                        <p className="typography typography__h1">3+</p>
                        <p className="typography typography__body-s">месяца работы</p>
                    </div>
                    <div>
                        <p className="typography typography__h1">250+</p>
                        <p className="typography typography__body-s">дизайнов макетов</p>
                    </div>
                </div>
            </section>

            <ProjectOtherProjects
                imageFirst={infomediji}
                nameFirst={'Infomediji'}
                textFirst={'HR Брендинг для компании в сфере виртуальной реальности'}
                openPageFirst={openInfomedijiPage}
                imageSecond={trice}
                nameSecond={'Trice'}
                textSecond={'Брендинг для международной линии средств по уходу за кожей'}
                openPageSecond={openTricePage}
            />
        </div>
    );
}