import { Image } from "../../Image/Image";
import { HeadingInfo } from "../../Project/HeadingInfo/HeadingInfo";
import { AboutProject } from "../../Project/AboutProject/AboutProject";
import { TitleTextProjects } from "../../Project/TitleTextProjects/TitleTextProjects";
import { ProjectOtherProjects } from "../../Project/ProjectOtherProjects/ProjectOtherProjects";

import imageBigFirst from "../../../images/infomediji-big-first.jpg";
import imageBigSecond from "../../../images/infomediji-big-second.jpg";
import imageBigThird from "../../../images/infomediji-big-third.jpg";
import imageBigFourth from "../../../images/infomediji-big-fourth.jpg";
import imageBigFifth from "../../../images/infomediji-big-fifth.jpg";
import imageBigSixth from "../../../images/infomediji-big-sixth.jpg";
import imageBigSeventh from "../../../images/infomediji-big-seventh.jpg";
import imageBigEighth from "../../../images/infomediji-big-eighth.jpg";
import imageBigNinth from "../../../images/infomediji-big-ninth.jpg";
import imageBigTenth from "../../../images/infomediji-big-tenth.jpg";
import imageBigEleventh from "../../../images/infomediji-big-eleventh.jpg";
import imageBigTwelfth from "../../../images/infomediji-big-twelfth.jpg";

import imageMediumFirst from "../../../images/infomediji-medium-first.jpg";
import imageMediumSecond from "../../../images/infomediji-medium-second.jpg";
import imageMediumThird from "../../../images/infomediji-medium-third.jpg";
import imageMediumFourth from "../../../images/infomediji-medium-fourth.jpg";

import trice from "../../../images/main-projects-trice.jpg";
import goldenEgg from "../../../images/main-projects-golden-egg.jpg";

export const Infomediji = (props) => {
    const openTricePage = () => {
        props.history.push('/projects/trice');
    }

    const openGoldenEggPage = () => {
        props.history.push('/projects/golden-egg');
    }

    return (
        <div className="project">
            <HeadingInfo
                name={'Infomediji'}
                client={['Infomediji', 'HR бренд в области VR']}
                profile={['Брендинг']}
                date={'2024'}
            />

            <Image
                image={imageBigFirst}
                alt={'Infomediji'}
            />

            <AboutProject
                text={'Infomediji активно нанимает талантливых специалистов для работы в сфере виртуальной реальности.'}
            />

            <section className="project-task">
                <TitleTextProjects
                    title={'Проект и задача'}
                    text={['Наша задача заключалась в переосмыслении визуальной концепции бренда, формировании его Tone of Voice и демонстрации развития на различных носителях.', 'Мы объединили символы видео, сообщества и пространства VR в концепции логотипа, чтобы передать суть бренда и его инновационное видение.']}
                />

                <div>
                    <Image
                        image={imageBigSecond}
                        alt={'Infomediji'}
                    />

                    <Image
                        image={imageBigThird}
                        alt={'Infomediji'}
                    />

                    <Image
                        image={imageBigFourth}
                        alt={'Infomediji'}
                    />
                </div>
            </section>

            <section className="project-task">
                <TitleTextProjects
                    title={'Брендинг'}
                    text={['Наша команда разработала концепцию брендинга, используя красный электрический цвет как символ энергии людей и уникальности пространства VR. Мы добавили ощущение глубины с помощью градиентов и тонких паттернов на фоне, чтобы подчеркнуть динамичность и инаковость виртуальной реальности.']}
                />

                <div>
                    <Image
                        image={imageBigFifth}
                        alt={'Infomediji'}
                    />

                    <Image
                        image={imageBigSixth}
                        alt={'Infomediji'}
                    />

                    <div className="project__couple-images">
                        <Image
                            image={imageMediumFirst}
                            alt={'Infomediji'}
                        />

                        <Image
                            image={imageMediumSecond}
                            alt={'Infomediji'}
                        />
                    </div>

                    <Image
                        image={imageBigSeventh}
                        alt={'Infomediji'}
                    />

                    <Image
                        image={imageBigEighth}
                        alt={'Infomediji'}
                    />

                    <div className="project__couple-images">
                        <Image
                            image={imageMediumThird}
                            alt={'Infomediji'}
                        />

                        <Image
                            image={imageMediumFourth}
                            alt={'Infomediji'}
                        />
                    </div>

                    <Image
                        image={imageBigNinth}
                        alt={'Infomediji'}
                    />

                    <Image
                        image={imageBigTenth}
                        alt={'Infomediji'}
                    />

                    <Image
                        image={imageBigEleventh}
                        alt={'Infomediji'}
                    />

                    <Image
                        image={imageBigTwelfth}
                        alt={'Infomediji'}
                    />
                </div>

                <ProjectOtherProjects
                    imageFirst={goldenEgg}
                    nameFirst={'Golden Egg'}
                    textFirst={'Упаковали под ключ фудтех приложение для гастрономических гедонистов'}
                    openPageFirst={openGoldenEggPage}
                    imageSecond={trice}
                    nameSecond={'Trice'}
                    textSecond={'Брендинг для международной линии средств по уходу за кожей'}
                    openPageSecond={openTricePage}
                />
            </section>
        </div>
    )
}