import { HeadingInfo } from "../../Project/HeadingInfo/HeadingInfo";
import { AboutProject } from "../../Project/AboutProject/AboutProject";
import { TitleTextProjects } from "../../Project/TitleTextProjects/TitleTextProjects";
import { Image } from "../../Image/Image";
import { TextProjects } from "../../Project/TextProjects/TextProjects";
import { ProjectOtherProjects } from "../../Project/ProjectOtherProjects/ProjectOtherProjects";

import imageBigFirst from "../../../images/go-mining-big-first.jpg";
import imageBigSecond from "../../../images/go-mining-big-second.jpg";
import imageBigThird from "../../../images/go-mining-big-third.jpg";
import imageBigFourth from "../../../images/go-mining-big-fourth.jpg";
import imageBigFifth from "../../../images/go-mining-big-fifth.jpg";
import imageBigSixth from "../../../images/go-mining-big-sixth.jpg";
import imageBigSeventh from "../../../images/go-mining-big-seventh.jpg";
import imageBigEighth from "../../../images/go-mining-big-eighth.jpg";
import imageBigNinth from "../../../images/go-mining-big-ninth.jpg";
import imageBigTenth from "../../../images/go-mining-big-tenth.jpg";
import imageBigEleventh from "../../../images/go-mining-big-eleventh.jpg";

import imageMediumFirst from "../../../images/go-mining-medium-first.jpg";
import imageMediumSecond from "../../../images/go-mining-medium-second.jpg";
import imageMediumThird from "../../../images/go-mining-medium-third.jpg";
import imageMediumFourth from "../../../images/go-mining-medium-fourth.jpg";
import imageMediumFifth from "../../../images/go-mining-medium-fifth.jpg";
import imageMediumSixth from "../../../images/go-mining-medium-sixth.jpg";

import videoFirst from "../../../video/gomining_01.mp4";
import videoSecond from "../../../video/gomining_02.mp4";
import videoThird from "../../../video/gomining_03.mp4";
import videoFourth from "../../../video/gomining_04.mp4";

import myBusiness from "../../../images/crpp-big-first.jpg";
import dataSpike from "../../../images/dataspike-big-first.jpg";

export const GoMining = (props) => {
    const openCrppPage = () => {
        props.history.push('/projects/crpp');
    }

    const openDataspikePage = () => {
        props.history.push('/projects/dataspike');
    }

    return (
        <div className="project">
            <HeadingInfo
                name={'GoMining'}
                client={['GoMining', 'Платформа для майнинга биткоина']}
                profile={['Промо-сайт', 'Платформа']}
                date={'2023–2024'}
            />

            <section className="main-showreel">
                <div className="main-showreel__video-container">
                    <video className="main-showreel__video" autoPlay muted loop poster={imageBigFirst} playsInline>
                        <source src={videoFirst} type="video/mp4" />
                    </video>
                </div>
            </section>

            <AboutProject
                text={'GoMining — компания в сфере майнинга и аренды криптоферм на базе Биткоина'}
            />

            <section className="project-task">
                <TitleTextProjects
                    title={'Проект и задача'}
                    text={['GoMining предлагает удобный способ заработка на майнинге биткойнов через NFT. Пользователи сервиса легко и быстро могут приобрести виртуальный NFT-майнер и получать от него ежедневный доход.', 'Перед нами стояла непростая задача — сделать редизайн промо-сайта, подчеркнув основые особенности платформы и её уникальность на рынке, а также полностью переосмыслить процесс выбора и покупки NFT-майнеров.']}
                />

                <div className="main-showreel">
                    <div className="main-showreel__video-container">
                        <video className="main-showreel__video" autoPlay muted loop poster={imageBigSecond} playsInline>
                            <source src={videoSecond} type="video/mp4" />
                        </video>
                    </div>
                </div>
            </section>

            <section className="project-task_type_border-none">
                <TitleTextProjects
                    title={'Промо-сайт'}
                    text={['Редизайн главной', 'GoMining обратились к нам с задачей редизайна главной страницы их промо-сайта. Основной проблемой было то, что пользователи не сразу понимали, что сервис связан с майнингом, и как именно работают NFT в сервисе.']}
                />

                <Image
                    image={imageBigThird}
                    alt={'GoMining'}
                />
            </section>

            <section className="project-task_type_border-none project-task_type_padding-top">
                <TextProjects
                    text={['Концепция', 'Ключевым элементом промо-сайта стала 3D-модель майнера, которая не только создаёт wow-эффект, но и наглядно демонстрирует пользователям принципы работы майнинга при помощи NFT.']}
                />

                <div>
                    <div className="main-showreel">
                        <div className="main-showreel__video-container">
                            <video className="main-showreel__video" autoPlay muted loop poster={imageBigFourth} playsInline>
                                <source src={videoThird} type="video/mp4" />
                            </video>
                        </div>
                    </div>

                    <Image
                        image={imageBigFifth}
                        alt={'GoMining'}
                    />

                    <div className="main-showreel">
                        <div className="main-showreel__video-container">
                            <video className="main-showreel__video" autoPlay muted loop poster={imageBigSixth} playsInline>
                                <source src={videoFourth} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </div>
            </section>

            <section className="project-task_type_border-none project-task_type_padding-top">
                <TextProjects
                    text={['Ценность продукта', 'При создании концепции мы сосредоточились на разработке понятного нарратива о работе продукта и компании. Добавили блок с преимуществами, цифры, сравнительную таблицу с традиционным майнингом и информацию о партнёрах. Также собрали майнер-конструктор, чтобы пользователи могли ближе познакомиться с продуктом и сразу оценить его возможности.']}
                />

                <div>
                    <Image
                        image={imageBigSeventh}
                        alt={'GoMining'}
                    />

                    <Image
                        image={imageBigEighth}
                        alt={'GoMining'}
                    />

                    <Image
                        image={imageBigNinth}
                        alt={'GoMining'}
                    />
                </div>
            </section>

            <section className="project-task project-task_type_padding-top">
                <TextProjects
                    text={['Нам было важно сохранить фирменный стиль и узнаваемость GoMining, поэтому мы оставили фирменный фиолетовый цвет, но сделали страницу преимущественно в тёмных цветах, чтобы передать атмосферу майнинга.']}
                />

                <div className="project__couple-images">
                    <Image
                        image={imageMediumFirst}
                        alt={'GoMining'}
                    />

                    <Image
                        image={imageMediumSecond}
                        alt={'GoMining'}
                    />
                </div>
            </section>

            <section className="project-task_type_border-none">
                <TitleTextProjects
                    title={'Платформа'}
                    text={['Работа над платформой', 'Команда GoMining обратилась к нам для улучшения платформы и увеличения продаж майнеров. До редизайна пользователи не понимали различий между майнерами и их доходностью, а перегруженный каталог и сложные фильтры только затрудняли выбор.', 'В качестве первой итерации, мы предложили команде GoMining вместо каталога использовать удобный калькулятор, который помогает подбирать оптимальное майнер-портфолио под бюджет пользователя.']}
                />

                <Image
                    image={imageBigTenth}
                    alt={'GoMining'}
                />
            </section>

            <section className="project-task_type_border-none project-task_type_padding-top">
                <TextProjects
                    text={['Карточка товара и корзина', 'Мы упростили интерфейс карточки, оставив только ключевые показатели мощности и доходности NFT-майнера, а в корзину добавили статистику, чтобы пользователи могли оценить доходность всего майнинг-портфолио при покупке.']}
                />

                <div>
                    <div className="project__couple-images">
                        <Image
                            image={imageMediumThird}
                            alt={'GoMining'}
                        />

                        <Image
                            image={imageMediumFourth}
                            alt={'GoMining'}
                        />
                    </div>

                    <Image
                        image={imageBigEleventh}
                        alt={'GoMining'}
                    />
                </div>
            </section>

            <section className="project-task project-task_type_padding-top">
                <TextProjects
                    text={['Глобальные изменения', 'Благодаря пользовательским интервью и исследованиям, на последних этапах работы над платформой мы полностью пересмотрели процесс покупки NFT-майнеров и упростили его до двух кликов.', 'Теперь с помощью конструктора кастомного NFT-майнера пользователи могут легко выбрать его мощность, оценить доходность и быстро оплатить покупку с карты или крипто-кошелька.']}
                />

                <div className="project__couple-images">
                    <Image
                        image={imageMediumFifth}
                        alt={'GoMining'}
                    />

                    <Image
                        image={imageMediumSixth}
                        alt={'GoMining'}
                    />
                </div>
            </section>

            <ProjectOtherProjects
                imageFirst={myBusiness}
                nameFirst={'Мой Бизнес'}
                textFirst={'Портал по развитию малого и среднего бизнеса Санкт Петербурга'}
                openPageFirst={openCrppPage}
                imageSecond={dataSpike}
                nameSecond={'Data Spike'}
                textSecond={'Платформа для KYC верификации и AML-решений: клиенты доверяют нам уже 4 года на всех этапах роста'}
                openPageSecond={openDataspikePage}
            />
        </div>
    );
}