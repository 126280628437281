import { Image } from "../../Image/Image";
import { HeadingInfo } from "../../Project/HeadingInfo/HeadingInfo";
import { AboutProject } from "../../Project/AboutProject/AboutProject";
import { TitleTextProjects } from "../../Project/TitleTextProjects/TitleTextProjects";
import { ProjectOtherProjects } from "../../Project/ProjectOtherProjects/ProjectOtherProjects";

import imageBigFirst from "../../../images/trice-big-first.jpg";
import imageBigSecond from "../../../images/trice-big-second.jpg";
import imageBigThird from "../../../images/trice-big-third.jpg";
import imageBigFourth from "../../../images/trice-big-fourth.jpg";
import imageBigFifth from "../../../images/trice-big-fifth.jpg";
import imageBigSixth from "../../../images/trice-big-sixth.jpg";

import imageMediumFirst from "../../../images/trice-medium-first.jpg";
import imageMediumSecond from "../../../images/trice-medium-second.jpg";
import imageMediumThird from "../../../images/trice-medium-third.jpg";
import imageMediumFourth from "../../../images/trice-medium-fourth.jpg";
import imageMediumFifth from "../../../images/trice-medium-fifth.jpg";
import imageMediumSixth from "../../../images/trice-medium-sixth.jpg";
import imageMediumSeventh from "../../../images/trice-medium-seventh.jpg";
import imageMediumEighth from "../../../images/trice-medium-eighth.jpg";
import imageMediumNinth from "../../../images/trice-medium-ninth.jpg";
import imageMediumTenth from "../../../images/trice-medium-tenth.jpg";

import imageSmallFirst from "../../../images/trice-small-first.jpg";
import imageSmallSecond from "../../../images/trice-small-second.jpg";
import imageSmallThird from "../../../images/trice-small-third.jpg";
import imageSmallFourth from "../../../images/trice-small-fourth.jpg";
import imageSmallFifth from "../../../images/trice-small-fifth.jpg";
import imageSmallSixth from "../../../images/trice-small-sixth.jpg";

import goldenEgg from "../../../images/main-projects-golden-egg.jpg";
import goMining from "../../../images/main-projects-go-mining.png";

export const Trice = (props) => {
    const openGoldenEggPage = () => {
        props.history.push('/projects/golden-egg');
    }

    const openGoMiningPage = () => {
        props.history.push('/projects/go-mining');
    }

    return (
        <div className="project">
            <HeadingInfo
                name={'Trice'}
                client={['Trice', 'Косметический бренд']}
                profile={['Брендинг', 'Упаковка', 'Коммуникации']}
                date={'2023'}
            />

            <Image
                image={imageBigFirst}
                alt={'Trice'}
            />

            <AboutProject
                text={'Trice — международный бренд косметики, создающий продукты для женщин, которые ценят заботу о своей коже и стремятся к гармонии с собой.'}
            />

            <section className="project-task">
                <TitleTextProjects
                    title={'Проект и задача'}
                    text={['В 2023 году Trice представили свой первый продукт — набор увлажняющих и очищающих масок. Бренд доверил нашему агентству разработку брендинга.', 'Мы создали визуальную концепцию, включающую логотип, цветовую палитру, типографику и дизайн упаковки. Наша цель заключалась в том, чтобы передать ценности бренда и создать притягательный образ, который будет привлекать новую аудиторию.']}
                />

                <div>
                    <Image
                        image={imageBigSecond}
                        alt={'Trice'}
                    />

                    <div className="project__couple-images">
                        <Image
                            image={imageMediumFirst}
                            alt={'Trice'}
                        />

                        <Image
                            image={imageMediumSecond}
                            alt={'Trice'}
                        />
                    </div>

                    <div className="project__couple-images">
                        <Image
                            image={imageMediumThird}
                            alt={'Trice'}
                        />

                        <Image
                            image={imageMediumFourth}
                            alt={'Trice'}
                        />
                    </div>
                </div>
            </section>

            <section className="project-task">
                <TitleTextProjects
                    title={'Концепт'}
                    text={['В качестве основного элемента логотипа была выбрана ракушка — символ природной гармонии, утончённой красоты и заботы о себе, которая отражает философию бренда Trice. Мы создали несколько концепций упаковки, экспериментируя с различными стилями, шрифтами и цветами.']}
                />

                <div className="project-task__grid-container">
                    <Image
                        image={imageSmallFirst}
                        alt={'Trice'}
                    />

                    <Image
                        image={imageSmallSecond}
                        alt={'Trice'}
                    />

                    <Image
                        image={imageSmallThird}
                        alt={'Trice'}
                    />

                    <Image
                        image={imageSmallFourth}
                        alt={'Trice'}
                    />

                    <Image
                        image={imageSmallFifth}
                        alt={'Trice'}
                    />

                    <Image
                        image={imageSmallSixth}
                        alt={'Trice'}
                    />
                </div>
            </section>

            <section className="project-task">
                <TitleTextProjects
                    title={'Дизайн упаковки'}
                    text={['В итоге мы разработали дизайн для коллекции масок, учитывая эстетику и философию бренда. Увлажняющая маска получила элегантный и стильный чёрный тюбик, подчеркивающий её роскошные питательные свойства.', 'Очищающая маска была помещена в минималистичный белый тюбик, символизирующий чистоту и свежесть. В дальнейшем бренд сможет экспериментировать с цветовыми решениями упаковки, расширяя линейку новыми продуктами и создавая новые образы для каждого из них.']}
                />

                <div>
                    <Image
                        image={imageBigThird}
                        alt={'Trice'}
                    />

                    <div className="project__couple-images">
                        <Image
                            image={imageMediumFifth}
                            alt={'Trice'}
                        />

                        <Image
                            image={imageMediumSixth}
                            alt={'Trice'}
                        />
                    </div>

                    <Image
                        image={imageBigFourth}
                        alt={'Trice'}
                    />
                </div>
            </section>

            <section className="project-task">
                <TitleTextProjects
                    title={'Коммуникация бренда'}
                    text={['Мы подготовили разнообразные материалы для социальных сетей, сайта и печатной продукции.  Все материалы адаптированы для различных платформ, учитывая их специфику и аудиторию.', 'Кроме того, мы составили рекомендации по использованию фирменного стиля, включая правила  размещения логотипа, пропорции и цвета, чтобы гарантировать консистентность в представлении бренда на всех носителях.']}
                />

                <div>
                    <div className="project__couple-images">
                        <Image
                            image={imageMediumSeventh}
                            alt={'Trice'}
                        />

                        <Image
                            image={imageMediumEighth}
                            alt={'Trice'}
                        />
                    </div>

                    <Image
                        image={imageBigFifth}
                        alt={'Trice'}
                    />

                    <div className="project__couple-images">
                        <Image
                            image={imageMediumNinth}
                            alt={'Trice'}
                        />

                        <Image
                            image={imageMediumTenth}
                            alt={'Trice'}
                        />
                    </div>

                    <Image
                        image={imageBigSixth}
                        alt={'Trice'}
                    />
                </div>
            </section>

            <ProjectOtherProjects
                imageFirst={goldenEgg}
                nameFirst={'Golden Egg'}
                textFirst={'Упаковали под ключ фудтех приложение для гастрономических гедонистов'}
                openPageFirst={openGoldenEggPage}
                imageSecond={goMining}
                nameSecond={'GoMining'}
                textSecond={'Промо-сайт компании в сфере майнинга и аренды криптоферм на базе Биткоина'}
                openPageSecond={openGoMiningPage}
            />
        </div>
    );
}