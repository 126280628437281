export const Footer = (props) => {
    function openMail() {
        window.open('mailto:hi@makeitwow.io')
    }

    function openTelegram() {
        window.open('https://t.me/makeitwow');
    }

    function openBehance() {
        window.open('https://www.behance.net/makeitwow');
    }

    function openDribbble() {
        window.open('https://dribbble.com/makeitwow');
    }

    // function openInstagram() {
    //     window.open('https://www.instagram.com/makeitwow.io/');
    // }

    function openLinkedIn() {
        window.open('https://www.linkedin.com/company/make-it-wow/');
    }

    function openDprofile() {
        window.open('https://dprofile.ru/makeitwow');
    }

    return (
        <section className="footer">
            <div className="footer__text-content">
                <div className="footer__text-container">
                    <h2 className="footer__typography-first typography typography__h2">Давайте сделаем</h2>
                    <h2 className="footer__typography-second typography typography__h2">WOW проект вместе!</h2>
                </div>

                <button className="footer__contact-us-button" onClick={props.contactUsForm}>
                    <span className="typography typography__h2">Написать нам</span>
                </button>
            </div>

            <div className="footer__links-content">
                <div className="footer__links-container">
                    <div className="footer__typography-container">
                        <button className="footer__link" onClick={openTelegram}>
                            <span className="typography typography__body-l">Telegram</span>
                        </button>
                    </div>

                    <div className="footer__list_type_justify-content">
                        <div className="footer__list">
                            <button className="footer__link typography typography__body-l" onClick={openBehance}>
                                <span className="typography typography__body-l">Behance</span>
                            </button>

                            <button className="footer__link typography typography__body-l" onClick={openDribbble}>
                                <span className="typography typography__body-l">Dribbble</span>
                            </button>

                            {/* <button className="footer__link typography typography__body-l" onClick={openInstagram}>
                                <span className="typography typography__body-l">Instagram</span>
                            </button> */}

                            <button className="footer__link typography typography__body-l" onClick={openLinkedIn}>
                                <span className="typography typography__body-l">LinkedIn</span>
                            </button>

                            <button className="footer__link typography typography__body-l" onClick={openDprofile}>
                                <span className="typography typography__body-l">Dprofile</span>
                            </button>
                        </div>
                    </div>

                    <div className="footer__contact-us-button-container">
                        <button className="footer__contact-us-button typography typography__body-l" onClick={openMail}>
                            <span className="typography typography__body-l">hi@makeitwow.io</span>
                        </button>
                    </div>
                </div>

                <div className="footer__copyright-container">
                    <p className="typography typography__body-s">Copyright © Make It WOW 2024</p>
                </div>
            </div>
            <div className="footer__image" />
        </section>
    );
}