export const InfoPopup = (props) => {
    return (
        <div className={`info-popup ${props.isOpen ? 'info-popup_type_is-open' : ''}`}>
            <div className="info-popup__content">
                <button onClick={props.infoPopup} className="info-popup__close-button">
                    <svg xmlns="http://www.w3.org/2000/svg" fontSize='inherit' viewBox="0 0 36 36" fill="none">
                        <path d="M35.9999 35.294L35.294 35.9999L18.0001 18.7059L0.705956 36.0001L7.53882e-05 35.2942L17.2942 18.0001L0 0.705881L0.705881 0L18.0001 17.2942L35.2941 0.00013007L36 0.706011L18.7059 18.0001L35.9999 35.294Z" fill="currentColor"/>
                    </svg>
                </button>

                <div className="info-popup__info-container">
                    <div className="info-popup__logo">
                        <svg xmlns="http://www.w3.org/2000/svg" fontSize='inherit' viewBox="0 0 100 100" fill="none">
                            <rect width="100" height="100" rx="50" fill="currentColor"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M58.8915 54.3428L67.4143 40H71.9943L59.9711 59.7702H54.7622V45.5426L46.3089 59.7702H41.1029V45.4246L32.58 59.7702H28L40.026 40H45.2321V54.3428L53.7549 40H58.8915V54.3428ZM66.7021 57.3242C66.7021 55.8458 67.8874 54.6484 69.351 54.6484C70.8146 54.6484 72 55.8458 72 57.3242C72 58.8026 70.8146 60 69.351 60C67.8874 60 66.7021 58.8026 66.7021 57.3242Z" fill="white"/>
                        </svg>
                    </div>

                    <div className="info-popup__text-container">
                        <p className="typography typography__body-l">{props.error ? 'Упс!' : 'Спасибо за вашу заявку!'}</p>
                        <p className="typography typography__body-l">{props.error ? 'Что-то пошло не так,' : 'Наша команда свяжется с вами'}</p>
                        <p className="typography typography__body-l">{props.error ? 'попробуйте позже.' : 'в ближайшее время.'}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}